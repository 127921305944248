import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/layouts/Default"
import SchemaPodcastEpisode from "../components/layouts/schemas/SchemaPodcastEpisode"
import Breadcrumb from "../components/commons/Breadcrumb"
import Container from "../components/layouts/container/Container"
import H1 from "../components/typography/H1"
import H3 from "../components/typography/H3"
import P from "../components/typography/P"
import SvgSpotify from "../components/commons/svgs/SvgSpotify"
import SvgItunes from "../components/commons/svgs/SvgItunes"
import SvgGooglePodcast from "../components/commons/svgs/SvgGooglePodcast"
import Button from "../components/commons/Button"
import parse from "html-react-parser"
import { convertDate } from "../components/functions/convertDate"
import AdsBanner from "../components/commons/AdsBanner"

const Episodes = ({ data, location }) => {
  const [anchor, setAnchor] = useState(false)

  let {
    titel,
    titel_seo,
    einleitung_seo,
    einleitung,
    anchorfm,
    folgennummer,
    datum,
    cockpitModified,
    slug,
    titelbild,
    thema,
    spotify,
    itunes,
    googlepodcast,
    arbeitsmittel,
    text,
  } = data.allCockpitFolgen.nodes[0]

  let headerColor
  data.allCockpitThemen.nodes.forEach(element => {
    if (element.thema.value === thema.value.display) {
      headerColor = element.farbe.value
    }
  })

  const renderAnchor = () => {
    if (anchor === false) {
      return (
        <div className="p-8 bg-black">
          <p className="mb-3 text-center text-white">
            Mit dem Aufruf der Audiodatei erklärst Du Dich einverstanden, dass
            Deine Daten an AnchorFM übermittelt werden und das Du die
            Datenschutzerklärung gelesen hast.
          </p>
          <button
            onClick={() => setAnchor(true)}
            type="button"
            className={`flex items-center px-6 py-3  text-base text-center leading-6 font-medium rounded-md shadow-xl hover:shadow-none target:shadow-none focus:outline-none transition ease-in-out duration-150 border border-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-100 mx-auto`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              height={24}
              width={24}
              className="mr-3"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                clipRule="evenodd"
              />
            </svg>
            Akzeptieren und abspielen
          </button>
        </div>
      )
    }

    if (anchor === true) {
      return (
        <iframe
          src={anchorfm.value}
          height="auto"
          width="100%"
          frameBorder="0"
          scrolling="no"
          className="h-28"
          title={`${titel.value} - AnchorFM iFrame`}
        ></iframe>
      )
    }
  }

  return (
    <Layout
      location={location}
      canonical={`${data.site.siteMetadata.siteUrl}/folgen/${folgennummer.value}/${slug.slug}`}
      title={titel_seo.value}
      desc={einleitung_seo.value}
      imageFacebook={
        data.allCockpitFolgen.nodes[0].titelbild.value.childImageSharp.facebook
          .src
      }
      imageFacebookWidth={
        data.allCockpitFolgen.nodes[0].titelbild.value.childImageSharp.facebook
          .width
      }
      imageFacebookHeight={
        data.allCockpitFolgen.nodes[0].titelbild.value.childImageSharp.facebook
          .height
      }
      imageTwitter={
        data.allCockpitFolgen.nodes[0].titelbild.value.childImageSharp.twitter
          .src
      }
    >
      <Helmet>
        {/* Commento */}
        <script
          defer
          src="https://cdn.commento.io/js/commento.js"
          data-auto-init="true"
          data-no-fonts="true"
          data-page-id={`${data.site.siteMetadata.siteUrl}/${folgennummer.value}/${slug.slug}`}
          id="comments-script"
        ></script>
      </Helmet>
      <SchemaPodcastEpisode
        url={`${data.site.siteMetadata.siteUrl}/${folgennummer.value}/${slug.slug}`}
        title={titel.value}
        published={datum.value}
        modified={cockpitModified > datum.value ? cockpitModified : datum.value}
        description={einleitung.value}
        medialink={anchorfm.value}
      />
      <Breadcrumb
        pages={[
          { page: "Alle Folgen", to: "/folgen/" },
          {
            page: `${titel.value}`,
          },
        ]}
      />
      <Container className="my-8 mmd:my-16">
        <div>
          <div className="mx-auto mb-6 overflow-hidden shadow-xl rounded-xl max-w-prose">
            <Img
              fluid={titelbild.value.childImageSharp.fluid}
              className="duration-300 transform hover:scale-110 "
            />
          </div>

          <div className="mx-auto max-w-prose">
            <div className="mb-2 text-base text-gray-700">
              Folge: {folgennummer.value} - {convertDate(datum.value)} - Thema:{" "}
              <span>{thema.value.display}</span>
            </div>
            <div className="mb-3">
              <H1 className={`font-bold inline shadow-title-${headerColor}`}>
                {titel.value}
              </H1>
            </div>
            <div className="mb-6 prose prose-xl">
              <P>{einleitung.value}</P>
            </div>

            <div className="mt-8 mb-4">{renderAnchor()}</div>
            {spotify || itunes || googlepodcast ? (
              <div className="grid grid-cols-1 gap-3 mb-6 mmd:grid-cols-3">
                {spotify ? (
                  <a
                    href={spotify.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center p-3 text-white transition-shadow duration-300 border border-gray-300 rounded shadow hover:shadow-none"
                    style={{ backgroundColor: "#1DB954" }}
                  >
                    <SvgSpotify className="inline w-6 h-6 mr-3" /> Spotify
                  </a>
                ) : (
                  ""
                )}
                {itunes ? (
                  <a
                    href={itunes.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center p-3 duration-300 border border-gray-300 rounded shadow gap-x-3transition-shadow hover:shadow-none"
                  >
                    <SvgItunes className="inline w-6 h-6 mr-3" /> iTunes
                  </a>
                ) : (
                  ""
                )}
                {googlepodcast ? (
                  <a
                    href={googlepodcast.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center p-3 text-white transition-shadow duration-300 border border-gray-300 rounded shadow hover:shadow-none"
                    style={{ backgroundColor: "#4285F4" }}
                  >
                    <SvgGooglePodcast className="inline w-6 h-6 mr-3" /> Google
                    Podcast
                  </a>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            {arbeitsmittel && arbeitsmittel.value.length > 0 ? (
              <div className="relative px-3 py-5 my-12 border border-gray-300 shadow-xl">
                <div
                  className="absolute inline-block bg-white"
                  style={{ top: "-18px" }}
                >
                  <H3>Freebies</H3>
                </div>
                <div className="grid gap-3 pt-2">
                  {arbeitsmittel.value.map((download, i) => {
                    return (
                      <div className="inline-block" key={i}>
                        <Button
                          text={download.value.split("/").slice(-1)[0]}
                          href={`https://cms.summeallerdinge.de/${download.value}`}
                          target="_blank"
                          rel="noreferrer noopener"
                          className="break-all"
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="mb-6">
              <AdsBanner />
            </div>

            <div className="mb-3 prose prose-xl">
              {parse(
                text.value.replace(
                  /\/storage\/uploads\//g,
                  "https://cms.summeallerdinge.de/storage/uploads/"
                )
              )}
            </div>
            <hr className="my-8" />
            <div>
              <H3 className="mb-5">Schreibe einen Kommentar</H3>
              <div id="commento"></div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Episodes

export const query = graphql`
  query($id: String) {
    allCockpitFolgen(
      filter: { id: { eq: $id }, public: { value: { eq: true } } }
    ) {
      nodes {
        anchorfm {
          value
        }

        datum {
          value
        }
        einleitung {
          value
        }
        einleitung_seo {
          value
        }
        folgennummer {
          value
        }
        id
        slug {
          slug
        }
        spotify {
          value
        }
        itunes {
          value
        }
        googlepodcast {
          value
        }
        text {
          value
        }
        titel {
          value
        }
        thema {
          value {
            display
          }
        }
        titel_seo {
          value
        }
        titelbild {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
              facebook: fixed(height: 630, width: 1200) {
                src
                width
                height
              }
              twitter: fixed(height: 438, width: 876) {
                src
              }
            }
          }
        }
        arbeitsmittel {
          value {
            value
          }
        }
      }
    }

    allCockpitThemen {
      nodes {
        farbe {
          value
        }
        thema {
          value
        }
        slug {
          slug
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
